import useSWR from 'swr';

import { useAuthStore } from "./useAuthStore";
import { getTradeAssets } from "../services/trade";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  refreshInterval: 60000
};

const apiTradeAssets = (_: string) => getTradeAssets();

export const useApiTradeAssets = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? 'apiTradeAssets' : null, apiTradeAssets, options);
  return {
    data: data?.data,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
