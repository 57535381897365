import { useState, useEffect } from 'react';

import { useAuthStore } from "../../../hooks/useAuthStore";
import { executeTrade, cancelTrade } from '../../../services/trade';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()(theme => ({
  grid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    marginTop: theme.spacing(0.5),
  },  
  close: {
    margin: theme.spacing(1)
  }
}));

export const TradeModal = (props: any) => {
  const { currentUser } = useAuthStore();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const description = (props.trade ? `Sell ${props.trade.amount} ${props.trade.symbolSend} ( ${props.trade.rate} )` : '');

  const handleSubmit = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Executing Trade: <strong>${description}</strong> ... ` });
    executeTrade(props.trade?.tradeId).then((data: any) => {
      console.log('executeTrade', data);
      if (data) {
        setMessage({ type: 'success', description: `Trade execution initiated: <strong>${description}</strong> updated <small>at ${new Date().toString()}</small>` });
        props.handleSubmit(props.trade?.tradeId);
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Cancel Trade: <strong>${description}</strong> ... ` });
    cancelTrade(props.trade?.tradeId).then((data: any) => {
      console.log('cancelTrade', data);
      if (data.status) {
        setMessage({ type: 'success', description: `Cancel Trade requested: <strong>${description}</strong> updated <small>at ${new Date().toString()}</small>` });
        props.handleCancel(props.trade?.tradeId);
      } else {
        setMessage({ type: 'warning', description: `Unable to Cancel Trade: <strong>${data.message}</strong>` });
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
    }).finally(() => setLoading(false));
  };

  const handleClose = () => {
    props.closeModal();
  };

  useEffect(() => {
    setMessage({ type: 'info', description: '' });
  }, [props.open]);

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="space-between">
        <Grid item><DialogTitle id='form-dialog-title'>Confirm Trade</DialogTitle></Grid>
        <Grid item><IconButton color="primary" onClick={() => handleClose()} className={classes.close}><CloseIcon /></IconButton></Grid>
      </Grid>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>You are about to execute the following trade:</DialogContentText>
        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={4} className={classes.itemTitle}>Id</Grid><Grid item xs={8} className={classes.itemValue}>{props.trade?.tradeId}</Grid>
          <Grid item xs={4} className={classes.itemTitle}>Counterparty</Grid><Grid item xs={8} className={classes.itemValue}>{props.trade?.l1.username !== currentUser.username ? props.trade?.l1.username : props.trade?.l2.username}</Grid>
          {(props.trade?.userId === currentUser.userId) && (
            <>
              <Grid item xs={4} className={classes.itemTitle}>You Send</Grid><Grid item xs={8} className={classes.itemValue}>{Number(props.trade?.amount).toFixed(2)} {props.trade?.symbolSend}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>You Receive</Grid><Grid item xs={8} className={classes.itemValue}>{(Number(props.trade?.amount) * Number(props.trade?.rate)).toFixed(2)} {props.trade?.symbolRecv}</Grid>
            </>
          )}
          {(props.trade?.userId !== currentUser.userId) && (
            <>
              <Grid item xs={4} className={classes.itemTitle}>You Send</Grid><Grid item xs={8} className={classes.itemValue}>{(Number(props.trade?.amount) * Number(props.trade?.rate)).toFixed(2)} {props.trade?.symbolRecv}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>You Receive</Grid><Grid item xs={8} className={classes.itemValue}>{Number(props.trade?.amount).toFixed(2)} {props.trade?.symbolSend}</Grid>
            </>
          )}
          <Grid item xs={4} className={classes.itemTitle}>Fee</Grid><Grid item xs={8} className={classes.itemValue}>{props.trade?.l1.username === currentUser.username ? `${props.trade?.l1.feeAmount.toFixed(4)} ${props.trade?.l1.symbol} (${props.trade?.l1.feePercent}%)` : `${props.trade?.l2.feeAmount.toFixed(4)} ${props.trade?.l2.symbol} (${props.trade?.l2.feePercent}%)`}</Grid>
        </Grid>
        {message && message.description &&
          <Alert
            variant='outlined'
            severity={message.type as any}
            className={classes.alert}
            action={isLoading ? <div className={classes.progress}><CircularProgress color="secondary" size={20} /></div> : <></>}
          >
            <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" >
          <Grid item>
            <Button onClick={() => handleClose()} color='primary' disableElevation>close</Button>
          </Grid>
          <Grid item>
            <Button onClick={() => handleCancel()} color='primary' variant='outlined' disabled={isLoading || (props.trade?.l1.state !== 'CREATED' && props.trade?.l2.state !== 'CREATED')} disableElevation>Cancel Deal</Button>
            <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation style={{marginLeft: '8px'}}>Execute Trade</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
