import useSWR from 'swr';

import { useAuthStore } from "./useAuthStore";
import { online } from "../services/users";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  refreshInterval: 60000
};

const apiUsersOnline = ([_]: any[]) => online();

export const useApiUsersOnline = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiTradeList'] : null, apiUsersOnline, options)
  return {
    data: data || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
