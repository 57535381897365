import axios from "axios";
import { addDays } from 'date-fns';

const FIXER_API_KEY = process.env.REACT_APP_FIXER_API_KEY || '';

export const getTimeseriesData = async () => {
  try {
    const end = new Date().toISOString().substring(0, 10);
    const start = addDays(new Date(), -365).toISOString().substring(0, 10);
    const url = `https://data.fixer.io/api/timeseries?access_key=${FIXER_API_KEY}&base=AUD&symbols=USD&start_date=${start}&end_date=${end}`;
    // console.log('getTimeseriesData', url);
    const result = await axios.get(url);
    // console.log('getTimeseriesData', result);
    return result.data;
  } catch (e: any) {
    console.log('getLatestRate', e);
  }
}

export const getLatestRate = async () => {
  try {
    const url = `https://data.fixer.io/api/latest?access_key=${FIXER_API_KEY}&base=AUD&symbols=USD`;
    // console.log('getLatestRate', url);
    const result = await axios.get(url);
    // console.log('getLatestRate', result);
    return result.data;
  } catch (e: any) {
    console.log('getLatestRate', e);
  }
}