import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    fontSize: '0.8rem',
    padding: theme.spacing(1)
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  symbol: {
    fontWeight: 500
  },
  mono: {
    fontFamily: 'monospace'
  }  
}));

export const BalanceTable = (props: any) => {
  const { classes } = useStyles();
  return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left">
            {props.isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!props.isLoading && (
                <IconButton color="primary" disabled={props.isLoading} onClick={() => props.mutate()}><RefreshIcon /></IconButton>
              )}              
            </TableCell>
            <TableCell className={classes.cell} align="right"><span className={classes.heading}>Balance</span></TableCell>
            <TableCell className={classes.cell} align="right"><span className={classes.heading}>Available</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.map((row: any) => (
            <TableRow component="tr" key={row.symbol}>
              <TableCell className={classes.cell} align="left"><span className={classes.symbol}>{row.symbol}</span></TableCell>
              <TableCell className={classes.cell} align="right"><span className={classes.mono}>{row.blockchainBalance?.toFixed(2)}</span></TableCell>
              <TableCell className={classes.cell} align="right"><span className={classes.mono}>{row.workingBalance?.toFixed(2)}</span></TableCell>
              <TableCell className={classes.cell} align="center"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
};
