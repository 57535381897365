import { useState } from 'react';
import { formatDistance } from 'date-fns';
import clsx from 'clsx';

import { shrinkULID } from "../../../helpers/shrink";

import { ConfirmModal } from './ConfirmModal';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  symbol: {
    fontWeight: 500
  },
}));

export const OfferTable = (props: any) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<any>();

  const selectOffer = (id?: string) => {
    console.log('selectOffer', id);
    if (id) {
      const offer = props.data?.find((x: any) => x.offerId === id);
      console.log(offer);
      setSelectedOffer(offer);
    } else {
      setSelectedOffer(undefined);
    }
  }

  const handleOpenConfirmModal = (id: string) => {
    console.log('handleOpenConfirmModal', id);
    selectOffer(props.data?.find((x: any) => x.offerId === id));
    setOpenConfirmModal(true);
  };

  const submitConfirm = (id: string) => {
    console.log('submitConfirm', id);
    setOpenConfirmModal(false);
    selectOffer(id);
    props.mutate();
  };

  const closeConfirmModal = () => {
    console.log('closeTrade');
    setOpenConfirmModal(false);
    selectOffer();
    // props.mutate();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>ID</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Trade Direction</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Receive</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Exchange Rate</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Send</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Counterparty</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Expires</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
              {props.isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!props.isLoading && (
                <IconButton color="primary" disabled={props.isLoading} onClick={() => props.mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map((row: any) => (
            <TableRow component="tr" key={row.offerId} onClick={() => selectOffer(row.offerId)} className={clsx(classes.row, { [classes.selected]: (props.selectedOfferId === row.offerId) })}>
              <TableCell className={classes.cell} align="center"><span className={classes.symbol}>{shrinkULID(row.offerId)}</span></TableCell>
              <TableCell className={classes.cell} align="center">{`Buy ${row.symbolSend}`}</TableCell>
              <TableCell className={classes.cell} align="center">{row.amount} <span className={classes.symbol}>{row.symbolSend}</span></TableCell>
              <TableCell className={classes.cell} align="center">{Number(row.rate).toFixed(2)} <span className={classes.symbol}>{row.symbolSend}</span></TableCell>
              <TableCell className={classes.cell} align="center">{(row.amount * row.rate).toFixed(2)} <span className={classes.symbol}>{row.symbolRecv}</span></TableCell>
              <TableCell className={classes.cell} align="center">{row.username}</TableCell>
              <TableCell className={classes.cell} align="center">{formatDistance(new Date(row.expireAt), new Date())}</TableCell>
              <TableCell className={classes.cell} align="right">
                <Button variant="outlined" color="primary" disableElevation onClick={() => handleOpenConfirmModal(row.offerId)} startIcon={<LaunchIcon />}>trade</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmModal offer={selectedOffer} open={openConfirmModal} closeModal={closeConfirmModal} handleSubmit={submitConfirm} />
    </>
  );
};
