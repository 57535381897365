import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import clsx from 'clsx';

import { useAuthStore } from "../../../hooks/useAuthStore";
import { shrinkULID } from "../../../helpers/shrink";

import { TradeModal } from './TradeModal';
import { InfoModal } from '../../../shared/Trade/InfoModal';
import { TradeState } from '../../../shared/Trade/TradeState';
import { TradeInfoSml } from '../../../shared/Trade/TradeInfoSml';
import { TradeMessagesSml } from '../../../shared/Trade/TradeMessagesSml';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  symbol: {
    fontWeight: 500
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SendAsset = (props: any) => {
  const { classes } = useStyles();
  if (props.currentUser.userId === props.trade?.userId) {
    return (<>{Number(props.trade?.amount).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolSend}</span></>);
  } else {
    return (<>{(props.trade?.amount * props.trade?.rate).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolRecv}</span></>);
  }
}

const ReceiveAsset = (props: any) => {
  const { classes } = useStyles();
  if (props.currentUser.userId === props.trade?.userId) {
    return (<>{(props.trade?.amount * props.trade?.rate).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolRecv}</span></>);
  } else {
    return (<>{Number(props.trade?.amount).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolSend}</span></>);
  }
}

const Counterparty = (props: any) => {
  if (props.currentUser.userId === props.trade?.l1.userId) {
    return props.trade?.l2.username;
  } else {
    return props.trade?.l1.username;
  }
}

const isCreated = (trade: any, userId: string) => {
  return (
    (trade.l1.state === 'CREATED' && trade.l1.userId === userId) ||
    (trade.l2.state === 'CREATED' && trade.l2.userId === userId)
  );
}

export const TradeTable = (props: any) => {
  const { currentUser } = useAuthStore();
  const [openTradeModal, setOpenTradeModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props.data) {
      if (window.location.hash.indexOf('#') === 0) {
        console.log(window.location.hash);
        const tradeId = window.location.hash.substring(1);
        const trade = props.data?.find((x: any) => x.tradeId === tradeId);
        if (trade) {
          navigate(location.pathname);
          if (!openTradeModal && !openInfoModal) {
            if (isCreated(trade, currentUser.userId)) {
              handleOpenTradeModal(tradeId);
            } else {
              handleOpenInfoModal(tradeId);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash, props.data]);

  const selectTrade = (id?: string) => {
    console.log('selectTrade', id);
    if (id) {
      const trade = props.data?.find((x: any) => x.tradeId === id);
      if (trade) {
        console.log('selectedTrade', trade);
        setSelectedTrade(trade);
        return true;
      }
    }
    setSelectedTrade(undefined);
    return false;
  }

  const handleOpenTradeModal = (id: string) => {
    console.log('handleOpenTradeModal', id);
    if (selectTrade(id)) setOpenTradeModal(true);
  };

  const handleOpenInfoModal = (id: string) => {
    console.log('handleOpenInfoModal', id);
    if (selectTrade(id)) setOpenInfoModal(true);
  };

  const submitTrade = (id: string) => {
    console.log('submitTrade', id);
    setOpenTradeModal(false);
    selectTrade(id);
    props.mutate();
    setOpenInfoModal(true);
  };

  const cancelTrade = (id: string) => {
    console.log('cancelTrade', id);
    setOpenTradeModal(false);
    selectTrade(id);
    props.mutate();
  };

  const closeTradeModal = () => {
    console.log('closeTrade');
    setOpenTradeModal(false);
    selectTrade();
    props.mutate();
  };

  const closeInfoModal = () => {
    console.log('closeInfo');
    setOpenInfoModal(false);
    selectTrade();
    props.mutate();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>ID</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Send</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Receive</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Counterparty</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Info</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              {props.isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!props.isLoading && (
                <IconButton color="primary" disabled={props.isLoading} onClick={() => props.refresh()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.map((row: any) => (
            <TableRow component="tr" key={row.tradeId} onClick={() => props.selectTrade(row.tradeId)} className={clsx(classes.row, { [classes.selected]: (props.selectedTrade === row.tradeId) })}>
              <TableCell className={classes.cell} align="center"><span className={classes.symbol}>{shrinkULID(row.tradeId)}</span></TableCell>
              <TableCell className={classes.cell} align="center"><SendAsset trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center"><ReceiveAsset trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center"><Counterparty trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center"><TradeState trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center">
                <Tooltip title={<TradeInfoSml trade={row} />}><InfoIcon className={classes.icon} /></Tooltip>
                {row.logs?.length > 0 && (
                  <Tooltip title={<TradeMessagesSml trade={row} />}><MessageIcon className={classes.icon} /></Tooltip>
                )}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                {isCreated(row, currentUser.userId) && (
                  <Button variant="outlined" color="primary" disableElevation onClick={() => handleOpenTradeModal(row.tradeId)} startIcon={<LaunchIcon />}>confirm</Button>
                )}
                {!isCreated(row, currentUser.userId) && (
                  <Button variant="outlined" color="primary" disableElevation onClick={() => handleOpenInfoModal(row.tradeId)} startIcon={<LaunchIcon />}>view</Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <InfoModal trade={selectedTrade} open={openInfoModal} closeModal={closeInfoModal} />
      <TradeModal trade={selectedTrade} open={openTradeModal} closeModal={closeTradeModal} handleSubmit={submitTrade} handleCancel={cancelTrade} />
    </>
  );
};
