import useSWR from 'swr';
import { getTimeseriesData } from "../services/fixer";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiTimeseriesData = (_: string) => getTimeseriesData();

export const useFixerTimeseries = () => {
  const { data, error, isValidating, mutate } = useSWR('apiTimeseriesData', apiTimeseriesData, options);
  return {
    data: data,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
