import { useEffect } from 'react';

import { useAuthStore } from "../../hooks/useAuthStore";
import { useNavigate } from "react-router-dom";

import { AppLayout } from '../../shared/AppLayout/AppLayout';
// import StarkwareLogo from '../../assets/logos/starkware-logo.svg';

import { Chart } from './Chart';
// import { Trades } from './Trades';
// import { Listings } from './Listings';
import { Offers } from './Offers';
// import { Transactions } from './Transactions';
// import { Partners } from './Partners';

import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  builtContainer: {
    paddingTop: '12px'
  },
  built: {
    color: '#28286E',
    paddingRight: theme.spacing(1)
  },
}));

export const Overview = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <h1 className={classes.title}>Overview</h1>
            <h4 className={classes.subtitle}><span>Buy &amp; Sell</span> Tokenised Assets</h4>
          </Grid>
          <Grid item>

            {/* <Grid container className={classes.builtContainer} justifyContent="flex-end">
              <Grid item><span className={classes.built}>built together with</span></Grid>
              <Grid item><img src={StarkwareLogo} alt='starkware' /></Grid>
            </Grid> */}

          </Grid>
        </Grid>

        <Chart />
        <Offers />
        {/* <Listings />
        <Trades />        
        <Transactions />
        <Partners /> */}

      </div>
    </AppLayout>
  );
};
