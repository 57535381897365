import { useState, useEffect } from 'react'

import { useFixerTimeseries } from '../../hooks/useFixerTimeseries';
import { CanvasSkeleton } from "../UI/CanvasSkeleton";
import { ExchangeRateChart } from './ExchangeRateChart';

import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  heading: {
    fontSize: '16',
    fontWeight: 500,
    color: '#333042',
  },
  builtContainer: {
    paddingTop: '12px'
  },
  built: {
    color: '#28286E',
    paddingRight: theme.spacing(1)
  },
  skeleton: {
    minHeight: '448px',
    marginBottom: theme.spacing(3),
  }
}));

export const ChartContainer = () => {
  const [chartData, setChartData] = useState<any[][]>();
  const { data } = useFixerTimeseries();

  useEffect(() => {
    if (data) {
      const chart = Object.entries(data.rates).map((obj: any[]) => [new Date(obj[0]).getTime(), obj[1].USD]);
      setChartData(chart);
    }
  }, [data]);

  const { classes } = useStyles();
  return (
    <>
      {!chartData && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {chartData && (
        <Paper className={classes.paper}>
          <ExchangeRateChart chartData={chartData} />
        </Paper>
      )}
    </>
  );
}