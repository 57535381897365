import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/useAuthStore";
import { MainPageLoader } from "../../shared/MainPageLoader/MainPageLoader";

export const Home = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      navigate('/overview');
    } else {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  return (
    <MainPageLoader />
  );
};
