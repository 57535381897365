import useSWR from 'swr';

import { useAuthStore } from "./useAuthStore";
import { listOffers } from "../services/trade";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  refreshInterval: 60000
};

const apiTradeOffers = ([_, type, from]: any[]) => listOffers(type, from);

export const useApiTradeOffers = (type: string, from: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiTradeOffers', type, from] : null, apiTradeOffers, options)
  return {
    data: data?.data || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
