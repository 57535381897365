import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/useAuthStore";
import { useDataStore } from "../../hooks/useDataStore";

import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { Avatar } from '../Picture/Avatar';
import { BalanceTable } from "./BalanceTable";

import { makeStyles } from 'tss-react/mui';
import { Paper, Grid, Button } from '@mui/material';
// import TradeIcon from '@mui/icons-material/ShoppingCartOutlined';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  code: {
    display: 'inline-block',
    width: '100%',
    maxWidth: '80px',
    textAlign: 'right'
  },
  avatar: {
    maxWidth: '130px',
    margin: '0 auto',
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  }
}));

export const Wallet = () => {
  const { dataBalance, isRefreshingBalance, refreshBalance } = useDataStore();
  const { currentUser } = useAuthStore();
  const navigate = useNavigate();

  const handleListing = () => {
    if (window.location.pathname === '/trade' || window.location.pathname === '/listings') {
      navigate('#new-listing');
    } else {
      navigate('/trade#new-listing');
    }
  }
  // const handleTrade = () => {
  //   navigate('/trade');
  // }

  const { classes } = useStyles();
  return (
    <>
      {(!dataBalance || dataBalance.length === 0 || !currentUser?.username) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {(dataBalance?.length > 0) && currentUser?.username && (
        <Paper className={classes.paper}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
            <Grid item md={4} sm={4} xs={12} textAlign="center">
              <h3 className={classes.title}>{currentUser.username}</h3>
              <div className={classes.avatar}><Avatar size="normal" picture={currentUser.picture} /></div>
            </Grid>
            <Grid item md={8} sm={8} xs={12} textAlign="right">
              <BalanceTable data={dataBalance} mutate={refreshBalance} isLoading={isRefreshingBalance} />
              <div>
                <Button variant="contained" size='small' color="primary" disableElevation startIcon={<WalletIcon />} className={classes.button} onClick={() => handleListing()}>create deal</Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}