import { useState, useEffect, useMemo } from 'react';
import { addDays, startOfDay, format } from 'date-fns';

import { useFixerLatest } from '../../../hooks/useFixerLatest';
import { useApiTradeAssets } from '../../../hooks/useApiTradeAssets';
import { createTradeOffer, updateTradeOffer } from "../../../services/trade";

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles()(theme => ({
  form: {
    marginTop: theme.spacing(3)
  },
  select: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    marginTop: theme.spacing(0.5),
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  btnGroup: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  refresh: {
    margin: theme.spacing(1, 0, 1, 1)
  },
  close: {
    margin: theme.spacing(1, 1, 1, 0)
  }
}));

export const EditModal = (props: any) => {
  const { data: assetsData, isLoading: assetsLoading, mutate: assetsMutate } = useApiTradeAssets();
  const { data: fixerData, isLoading: fixerLoading, mutate: fixerMutate } = useFixerLatest();
  const rateUSD = useMemo(() => fixerData?.rates?.USD || 0, [fixerData]);

  const [send, setSend] = useState('');
  const [recv, setRecv] = useState('');
  const [amount, setAmount] = useState('');
  const [rate, setRate] = useState('');
  const [expire, setExpire] = useState('');
  const [status, setStatus] = useState(true);

  const [errorSend, setErrorSend] = useState('');
  const [errorRecv, setErrorRecv] = useState('');
  const [errorAmount, setErrorAmount] = useState('');
  const [errorRate, setErrorRate] = useState('');
  const [errorExpire, setErrorExpire] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const symbolSend = useMemo(() => assetsData?.find((x: any) => x.assetId === send)?.assetSymbol || '', [assetsData, send]);
  const symbolRecv = useMemo(() => assetsData?.find((x: any) => x.assetId === recv)?.assetSymbol || '', [assetsData, recv]);

  useEffect(() => {
    if (props.offer) {
      setSend(props.offer.assetSend);
      setRecv(props.offer.assetRecv);
      setAmount(props.offer.amount);
      setRate(props.offer.rate);
      setExpire(props.offer.expireAt);
      setStatus(props.offer.status);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.offer])

  const clearForm = () => {
    setSend('');
    setRecv('');
    setAmount('');
    setRate('');
    setExpire(format(startOfDay(addDays(new Date(), 1)), 'dd-MMM-yyyy HH:mm zzz'));
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorSend('');
    setErrorRecv('');
    setErrorAmount('');
    setErrorRate('');
    setErrorExpire('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (send?.length === 0) {
      setErrorSend('Select the asset to sell');
      return false;
    }
    if (recv?.length === 0) {
      setErrorRecv('Select the asset to buy');
      return false;
    }
    if (amount?.length === 0) {
      setErrorAmount('Enter the amount to sell');
      return false;
    }
    if (rate?.length === 0) {
      setErrorRate('Enter the rate to buy');
      return false;
    }
    if (expire?.length === 0) {
      setErrorExpire('Enter the expiry date');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  useEffect(() => {
    if (assetsData && send && (!recv || send === recv)) {
      const asset = assetsData.find((x: any) => x.assetId !== send);
      if (asset) {
        setRecv(asset?.assetId);
      }
    }
  }, [send, recv, assetsData]);

  useEffect(() => {
    if (symbolSend === 'eAUD' && symbolRecv === 'USDC') {
      setRate(rateUSD.toFixed(2));
    }
    if (symbolSend === 'USDC' && symbolRecv === 'eAUD') {
      setRate((1 / rateUSD).toFixed(2));
    }
  }, [symbolSend, symbolRecv, rateUSD]);

  useEffect(() => {
    setMessage({ type: 'info', description: '' });
  }, [props.open]);

  const description = useMemo(() => `Sell ${amount} ${symbolSend} ( ${rate} )`, [symbolSend, amount, rate])

  const rateMessage = (errorRate ? errorRate : (rateUSD ? `Current Ex. Rate: 1 AUD = ${rateUSD.toFixed(4)} USD` : ' '));
  const recvMessage = ((Number(amount) > 0 && Number(rate) > 0) ? `You will receive: ${(Number(amount) * Number(rate)).toFixed(2)} ${symbolRecv}` : ' ');

  // const btnEnabled = status ? "contained" : "outlined";
  // const btnDisabled = status ? "outlined" : "contained";

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.offer) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating offer: <strong>${description}</strong> ... ` });
    const assetSend = assetsData?.find((x: any) => x.assetId === send);
    const assetRecv = assetsData?.find((x: any) => x.assetId === recv);
    if (assetSend && assetRecv) {
      createTradeOffer(assetSend.assetId, assetSend.assetSymbol, assetRecv.assetId, assetRecv.assetSymbol, amount, rate, new Date(expire).toISOString(), status).then((data) => {
        console.log('CreateOffer', data);
        setLoading(false);
        if (data) {
          setMessage({ type: 'success', description: `Offer: <strong>${description}</strong> created <small>at ${new Date().toString()}</small>` });
          clearErrors();
          clearForm();
          props.handleSubmit();
        }
      }).catch(e => {
        console.log(e);
        setMessage({ type: 'error', description: e.message });
        setLoading(false);
      });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating offer details: <strong>${description}</strong> ... ` });
    const assetSend = assetsData?.find((x: any) => x.assetId === send);
    const assetRecv = assetsData?.find((x: any) => x.assetId === recv);
    if (assetSend && assetRecv) {
      updateTradeOffer(props.offer?.offerId, assetSend.assetId, assetSend.assetSymbol, assetRecv.assetId, assetRecv.assetSymbol, amount, rate, new Date(expire).toISOString(), status).then((data) => {
        console.log('UpdateOffer', data);
        setLoading(false);
        if (data) {
          setMessage({ type: 'success', description: `Offer: <strong>${description}</strong> updated <small>at ${new Date().toString()}</small>` });
          props.handleSubmit(props.offer?.offerId);
          clearErrors();
          clearForm();
        }
      }).catch(e => {
        console.log(e);
        setMessage({ type: 'error', description: e.message });
        setLoading(false);
      });
    }
  };

  const handleClose = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const changeRecv = (val: string) => {
    console.log('changeRecv', val);
    const asset = assetsData?.find((x: any) => x.assetId === val);
    console.log('asset', asset);
    setRecv(asset);
  }
  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="space-between">
        <Grid item>
          {props.offer && (
            <DialogTitle id='form-dialog-title'>Update Deal</DialogTitle>
          )}
          {!props.offer && (
            <DialogTitle id='form-dialog-title'>Create New Deal</DialogTitle>
          )}
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={() => assetsMutate()} className={classes.refresh}>
            {assetsLoading && (<CircularProgress color="primary" size={24} />)}
            {!assetsLoading && (<RefreshIcon />)}
          </IconButton>
          <IconButton color="primary" onClick={() => handleClose()} className={classes.close}><CloseIcon /></IconButton>
        </Grid>
      </Grid>
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.offer && (
          <DialogContentText>Update the deal details:</DialogContentText>
        )}
        {!props.offer && (
          <DialogContentText>Input the deal details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit} className={classes.form}>
          <Grid container alignItems='flex-start' spacing={3}>
            <Grid item sm={6}>
              <FormControl className={classes.select}>
                <InputLabel id="label-sell">Sell</InputLabel>
                <Select
                  labelId="label-sell"
                  id="select-sell"
                  label="Sell"
                  value={send}
                  error={errorSend.length > 0}
                  onChange={(e: any) => setSend(e.target.value)}
                >
                  {assetsData?.map((x: any) => (
                    <MenuItem key={x.assetId} value={x.assetId}>{x.assetSymbol}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errorSend.length > 0 ? errorSend : 'Select the asset you would like to sell'}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='amount'
                label='Amount'
                value={amount}
                error={errorAmount.length > 0}
                helperText={errorAmount}
                onChange={(e: any) => setAmount(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                placeholder='1000'
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl className={classes.select}>
                <InputLabel id="label-recv">Buy</InputLabel>
                <Select
                  labelId="label-recv"
                  id="select-recv"
                  value={recv}
                  label="Buy"
                  error={errorRecv.length > 0}
                  onChange={(e: any) => setRecv(e.target.value)}
                >
                  {assetsData?.map((x: any) => (
                    <MenuItem key={x.assetId} value={x.assetId}>{x.assetSymbol}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errorRecv.length > 0 ? errorRecv : 'Select the asset you would like to buy'}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='rate'
                label='Exchange Rate'
                value={rate}
                error={errorRate.length > 0}
                helperText={rateMessage}
                onChange={(e: any) => setRate(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary" onClick={() => fixerMutate()} disabled={fixerLoading}>
                        {fixerLoading && (<CircularProgress color="primary" size={24} />)}
                        {!fixerLoading && (<RefreshIcon />)}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={rateUSD?.toFixed(2)}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='expire'
                label='Expire At'
                value={expire}
                error={errorExpire.length > 0}
                helperText={errorExpire}
                onChange={(e: any) => setExpire(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                placeholder={new Date().toISOString()}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='receive'
                label='Receive'
                helperText={recvMessage}
                value={`${(Number(amount) * Number(rate)).toFixed(2)} ${symbolRecv || ''}`}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
          {/* <ButtonGroup variant="outlined" disableElevation className={classes.btnGroup}>
            <Button variant={btnDisabled} onClick={() => setStatus(false)}>disabled</Button>
            <Button variant={btnEnabled} onClick={() => setStatus(true)}>enabled</Button>
          </ButtonGroup> */}
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <div className={classes.progress}><CircularProgress color="secondary" size={20} /></div> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' disabled={isLoading} variant='contained' disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
