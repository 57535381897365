import { useMemo } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

export const ExchangeRateChart = (props: any) => {
  const colors = Highcharts.getOptions().colors || [];
  const color = colors[0];
  const options = useMemo(() => ({
    rangeSelector: {
      selected: 0
    },
    title: {
      text: 'AUD/USD Exchange Rate',
      style: { color: "#333042", fontFamily: 'Cera Pro', fontSize: "16px", fontWeight: 500 },
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: false,
      series: {
        accessibility: {
          exposeAsGroupOnly: true
        }
      }
    },
    scrollablePlotArea: {
      scrollPositionX: 1
    },
    series: [{
      name: 'AUD',
      data: props.chartData,
      type: 'area',
      threshold: null,
      tooltip: {
        valueDecimals: 4
      },
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, color],
          [1, Highcharts.color(color).setOpacity(0).get('rgba')]
        ]
      }
    }]
  }), [props.chartData, color]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    </div>
  );
};
