import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/useAuthStore";

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { Profile } from '../../shared/Card/Profile';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(0.5),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  text: {
    marginBlockStart: 0,
    marginBlockEnd: theme.spacing(2),
  },
  label: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingBottom: theme.spacing(0.5),
    textTransform: 'uppercase',
    fontFamily: '"Roboto Mono"',
    fontWeight: 400,
    color: '#666'
  }
}));

export const Settings = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Settings</h1>
        <Profile />
      </div>
    </AppLayout>
  );
};