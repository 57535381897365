import { useDataStore } from "../../hooks/useDataStore";

import { OfferTable } from '../Listings/Offer/OfferTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#333042',
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  }
}));

export const Listings = () => {
  const { dataOfferMine, isLoadingOfferMine, mutateOfferMine  } = useDataStore();
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
          <h2 className={classes.heading}>My Deals</h2>
          <OfferTable data={dataOfferMine} isLoading={isLoadingOfferMine} mutate={mutateOfferMine} />
        </Paper>
    </div>
  );
};