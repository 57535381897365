import { ChartContainer } from '../../shared/Chart/ChartContainer';
import { Wallet } from '../../shared/Card/Wallet';
import { Online } from '../../shared/Card/Online';

import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cards: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#333042',
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  }
}));

export const Chart = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" spacing={3} className={classes.cards}>
        <Grid item xl={4} md={12}>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={6} md={12} sm={12} xs={12}><Wallet /></Grid>
            <Grid item xl={12} lg={6} md={12} sm={12} xs={12}><Online /></Grid>
          </Grid>
        </Grid>
        <Grid item xl={8} md={12}>
          <ChartContainer />
        </Grid>
      </Grid>
    </div>
  );
};