import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { NotifyProvider } from './context/NotifyProvider';

import { Home } from "./pages/Home/Home";
import { SignIn } from "./pages/SignIn/SignIn";
import { Overview } from "./pages/Overview/Overview";
import { Listings } from "./pages/Listings/Listings";
import { Trade } from "./pages/Trade/Trade";
import { Transactions } from "./pages/Transactions/Transactions";
import { Settings } from "./pages/Settings/Settings";

export const Router = () => {
  return (
    <BrowserRouter>
      <NotifyProvider>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/signin"} element={<SignIn />} />
          <Route path={"/overview"} element={<Overview />} />
          <Route path={"/listings"} element={<Listings />} />
          <Route path={"/trade"} element={<Trade />} />
          <Route path={"/transactions"} element={<Transactions />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </NotifyProvider>
    </BrowserRouter>
  );
};
