import useSWR from 'swr';
import { getLatestRate } from "../services/fixer";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true
};

const apiLatestRate = (_: string) => getLatestRate();

export const useFixerLatest = () => {
  const { data, error, isValidating, mutate } = useSWR('apiLatestRate', apiLatestRate, options);
  return {
    data: data,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
