import { useDataStore } from "../../hooks/useDataStore";
import { useApiTradeFX } from "../../hooks/useApiTradeFX";

import { TradeInfo } from './TradeInfo';
// import { TradeProgress } from './TradeProgress';
import { TradeStepper } from './TradeStepper';
import { TradeMessages } from './TradeMessagesTable';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  divider: {
    paddingTop: theme.spacing(1),
  },
  close: {
    margin: theme.spacing(1)
  }
}));
export const InfoModal = (props: any) => {
  const { data, isLoading, mutate } = useApiTradeFX(props.trade?.tradeId);
  const { refreshBalance } = useDataStore();

  const handleRefresh = () => {
    mutate();
    refreshBalance();
  };
  const handleClose = () => {
    props.closeModal();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="space-between">
        <Grid item><DialogTitle id='form-dialog-title'>Deal Details</DialogTitle></Grid>
        <Grid item>
          {isLoading && (
            <IconButton><CircularProgress color="primary" size={24} /></IconButton>
          )}
          {!isLoading && (
            <IconButton color="primary" disabled={isLoading} onClick={() => handleRefresh()}><RefreshIcon /></IconButton>
          )}
          <IconButton color="primary" onClick={() => handleClose()} className={classes.close}><CloseIcon /></IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {data && (
          <>
            <TradeInfo trade={data} />
            <TradeStepper trade={data} />
            <TradeMessages trade={data} height='250px' />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color='primary' disableElevation>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
