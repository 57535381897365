import React, { createContext, useEffect, useState } from 'react';
import { getLogoutLink, getMe, ping } from '../services/users';
import { useInterval } from '../hooks/useInterval';

export interface IUser {
  userId: string;
  email: string;
  name: string;
  firstName?: string;
  lastName?: string;
  username: string;
  picture: string;
  updatedAt: string;
  isEmailVerified: boolean;
  isAuthorized: boolean;
}

interface IAuthContext {
  loading: boolean;
  isAuthorized: boolean;
  idToken?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  currentUser: IUser,
  setCurrentUser: (user: IUser) => void,
  setIdToken: (token: string) => void;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  logout: () => void;
}

const USER_DEFAULT: IUser = {
  userId: '',
  email: '',
  isEmailVerified: false,
  name: '',
  username: '',
  picture: '',
  updatedAt: '',
  isAuthorized: false,
  firstName: '',
};

const CONTEXT_DEFAULT = {
  loading: false,
  isAuthorized: false,
  idToken: '',
  accessToken: '',
  refreshToken: '',
  currentUser: USER_DEFAULT,
  setCurrentUser: () => { },
  setIdToken: () => { },
  setAccessToken: () => { },
  setRefreshToken: () => { },
  logout: () => { },
};

export const AuthContext = createContext<IAuthContext>(CONTEXT_DEFAULT);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const isAuthorized = localStorage.getItem('accessToken')
  const [idToken, _setIdToken] = useState(localStorage.getItem('idToken'));
  const [accessToken, _setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, _setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [currentUser, setCurrentUser] = useState<IUser>(USER_DEFAULT)
  const [loading, setLoading] = useState(false);

  useInterval(() => isAuthorized && ping(), 60000);

  useEffect(() => {
    if (accessToken && !currentUser.isAuthorized) {
      setLoading(true);
      getMe()
        .then(res => {
          setLoading(false);
          // console.log('getMe', res);
          setCurrentUser({ ...res, isAuthorized: true });
        })
        .catch((e: any) => {
          setLoading(false);
          console.log('getMe', e.message);
        });
    }
  }, [accessToken, currentUser])

  const setIdToken = (token: string) => {
    localStorage.setItem('idToken', token);
    _setIdToken(token);
  };

  const setAccessToken = (token: string) => {
    localStorage.setItem('accessToken', token);
    _setAccessToken(token);
  };

  const setRefreshToken = (token: string) => {
    localStorage.setItem('refreshToken', token);
    _setRefreshToken(token);
  };

  const logout = () => {
    getLogoutLink().then((res: any) => {
      localStorage.clear();
      _setIdToken(null);
      _setAccessToken(null);
      _setRefreshToken(null);
      setCurrentUser(USER_DEFAULT);
      window.location.href = res;
    }).catch((e: any) => {
      console.log('Unable to logout', e.mesage);
    });
  };

  const value = {
    currentUser,
    setCurrentUser,
    isAuthorized: Boolean(isAuthorized),
    idToken,
    accessToken,
    refreshToken,
    setIdToken,
    setAccessToken,
    setRefreshToken,
    logout,
    loading,
  };
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
