import useSWR from 'swr';

import { useAuthStore } from "./useAuthStore";
import { getTradeFX } from "../services/trade";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  refreshInterval: 5000,
};

const apiTradeFX = ([_, tradeId]: any[]) => getTradeFX(tradeId);

export const useApiTradeFX = (tradeId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && tradeId ? ['apiTradeFX', tradeId] : null, apiTradeFX, options)
  return {
    data: data?.data || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
