import { useAuthStore } from "../../hooks/useAuthStore";
import { shrinkULID } from "../../helpers/shrink";

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
}));

export const TradeInfoSml = (props: any) => {
  const { currentUser } = useAuthStore();
  const { classes } = useStyles();
  return (
    <>
      {currentUser && props.trade && (
          <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={5} className={classes.itemTitle}>Id</Grid><Grid item xs={7} className={classes.itemValue}>{shrinkULID(props.trade?.tradeId)}</Grid>
            {(props.trade?.userId === currentUser.userId) && (
              <>
                <Grid item xs={5} className={classes.itemTitle}>You Send</Grid><Grid item xs={7} className={classes.itemValue}>{Number(props.trade?.amount).toFixed(2)} {props.trade?.symbolSend}</Grid>
                <Grid item xs={5} className={classes.itemTitle}>You Receive</Grid><Grid item xs={7} className={classes.itemValue}>{(Number(props.trade?.amount) * Number(props.trade?.rate)).toFixed(2)} {props.trade?.symbolRecv}</Grid>
              </>
            )}
            {(props.trade?.userId !== currentUser.userId) && (
              <>
                <Grid item xs={5} className={classes.itemTitle}>You Send</Grid><Grid item xs={7} className={classes.itemValue}>{(Number(props.trade?.amount) * Number(props.trade?.rate)).toFixed(2)} {props.trade?.symbolRecv}</Grid>
                <Grid item xs={5} className={classes.itemTitle}>You Receive</Grid><Grid item xs={7} className={classes.itemValue}>{Number(props.trade?.amount).toFixed(2)} {props.trade?.symbolSend}</Grid>
              </>
            )}
            <Grid item xs={5} className={classes.itemTitle}>Counterparty</Grid><Grid item xs={7} className={classes.itemValue}>{props.trade?.l1?.userId !== currentUser.userId ? props.trade?.l1?.username : props.trade?.l2?.username}</Grid>
            <Grid item xs={5} className={classes.itemTitle}>Your Status</Grid><Grid item xs={7} className={classes.itemValue}>{props.trade?.l1?.userId === currentUser.userId ? props.trade?.l1?.state : props.trade?.l2?.state}</Grid>
            <Grid item xs={5} className={classes.itemTitle}>Their Status</Grid><Grid item xs={7} className={classes.itemValue}>{props.trade?.l1?.userId !== currentUser.userId ? props.trade?.l1?.state : props.trade?.l2?.state}</Grid>
          </Grid>
      )}
    </>
  );
}
