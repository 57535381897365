import React, { useState } from "react";
import { formatDistance } from 'date-fns';

import { useAuthStore } from "../../hooks/useAuthStore";
import { useInterval } from "../../hooks/useInterval";
import { online } from "../../services/users";

import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { Avatar } from '../Picture/Avatar';

import { Paper, Grid, IconButton, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 500,
    color: '#333042',
  },
  duration: {
    marginTop: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
  },
  picture: {
    maxWidth: '40px'
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },  
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  }
}));

export const Online = () => {
  const { isAuthorized } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState< any[]>();

  const handleRefresh = () => {
    if (!isLoading) {
      setIsLoading(true);
      online()
        .then((data: any) => setUsers(data))
        .catch((e: any) => console.log(e))
        .finally(()=> setIsLoading(false));
    }
  };
  useInterval(() => isAuthorized && handleRefresh(), 60000, true);

  const { classes } = useStyles();
  return (
    <>
      {(!users) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {(users) && (
        <Paper className={classes.paper}>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item xs={9}>
              <h3 className={classes.title}>Online Counterparties</h3>
            </Grid>
            <Grid item xs={3} textAlign="right">
              {isLoading && (
                <div className={classes.progress}>
                  <CircularProgress color="primary" size={24} />
                </div>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => handleRefresh()}><RefreshIcon /></IconButton>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={1}>
            {users?.map((user: any, index: number) => (
              <React.Fragment key={index}>
                <Grid item xs={2}>
                  <div className={classes.picture}><Avatar size="sml" picture={user.picture} /></div>
                </Grid>
                <Grid item xs={5}>
                  <h3 className={classes.subtitle}>{user.username}</h3>
                </Grid>
                <Grid item xs={5} textAlign="right">
                  <h3 className={classes.duration}>{formatDistance(new Date(user.updatedAt), new Date())}</h3>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Paper>
      )}
    </>
  );
}