import { Router } from './Router';
import { ContextProvider } from './context/ContextProvider';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';

export const App = () => {
  return (
    <div className="App">
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </ContextProvider>
    </div>
  );
}
