import useSWR from 'swr';

import { useAuthStore } from "./useAuthStore";
import { getTradeStats } from "../services/trade";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  refreshInterval: 60000
};

const apiTradeStats = (_: string) => getTradeStats();

export const useApiTradeStats = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? 'apiTradeStats' : null, apiTradeStats, options);
  return {
    data: data?.data,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
