import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { shrinkULID } from "../../../helpers/shrink";
import { EditModal } from './EditModal';
import { DeleteModal } from './DeleteModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  symbol: {
    fontWeight: 500
  },
}));


export const OfferTable = (props: any) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<any>();

  useEffect(() => {
    if (window.location.hash === '#new-listing') {
      window.location.hash = '';
      handleAddNew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  const selectOffer = (id?: string) => {
    console.log('selectOffer', id);
    if (id) {
      const offer = props.data?.find((x: any) => x.offerId === id);
      console.log(offer);
      setSelectedOffer(offer);
    } else {
      setSelectedOffer(undefined);
    }
  }

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectOffer();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectOffer(id);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectOffer(id);
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectOffer(id);
    props.mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectOffer();
    props.mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectOffer();
    // props.mutate();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectOffer();
    // props.mutate();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>ID</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Counterparty</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Trade Direction</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Send</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Exchange Rate</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Receive</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Expires</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
              <IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton>
              {props.isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!props.isLoading && (
                <IconButton color="primary" disabled={props.isLoading} onClick={() => props.mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map((row: any) => (
            <TableRow component="tr" key={row.offerId} onClick={() => selectOffer(row.offerId)} className={clsx(classes.row, { [classes.selected]: (props.selectedOfferId === row.offerId) })}>
              <TableCell className={classes.cell} align="center"><span className={classes.symbol}>{shrinkULID(row.offerId)}</span></TableCell>
              <TableCell className={classes.cell} align="center">{row.username}</TableCell>
              <TableCell className={classes.cell} align="center">{`Sell ${row.symbolSend}`}</TableCell>
              <TableCell className={classes.cell} align="center">{row.amount} <span className={classes.symbol}>{row.symbolSend}</span></TableCell>
              <TableCell className={classes.cell} align="center">{Number(row.rate).toFixed(2)} <span className={classes.symbol}>{row.symbolSend}</span></TableCell>
              <TableCell className={classes.cell} align="center">{(row.amount * row.rate).toFixed(2)} <span className={classes.symbol}>{row.symbolRecv}</span></TableCell>
              <TableCell className={classes.cell} align="center">{format(new Date(row.expireAt), 'dd-MMM-yyyy HH:mm')}</TableCell>
              <TableCell className={classes.cell} align="right">
                <IconButton color="primary" onClick={() => handleOpenEditModal(row.offerId)}><EditIcon /></IconButton>
                <IconButton color="primary" onClick={() => handleOpenDeleteModal(row.offerId)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditModal offer={selectedOffer} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteModal offer={selectedOffer} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
