import { useEffect, useState, useMemo } from 'react';
import { deleteTradeOffer } from "../../../services/trade";

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    marginTop: theme.spacing(0.5),
  },
  content: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  heading1: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginTop: theme.spacing(2)
  },
  heading2: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginTop: theme.spacing(0.5)
  },
  close: {
    margin: theme.spacing(1)
  }
}));

export const DeleteModal = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
  };

  const description = useMemo(() => props?.offer ? `Sell ${props.offer.amount} ${props.offer.symbolSend} ( ${props.offer.rate} )` : '', [props.offer]);

  const handleSubmit = () => {
    console.log('handleSubmit');
    setLoading(true);
    setMessage({ type: 'warning', description: `Deleting Trade Offer: <strong>${description}</strong> ... ` });
    deleteTradeOffer(props.offer?.offerId).then((data) => {
      console.log('DeleteOffer', data);
      if (data) {
        setMessage({ type: 'success', description: `Trade Offer: <strong>${description}</strong> has been deleted <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleClose = () => {
    props.closeModal();
    clearErrors();
  };

  useEffect(() => {
    setMessage({ type: 'info', description: '' });
  }, [props.open]);

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <Grid container justifyContent="space-between">
        <Grid item><DialogTitle id='form-dialog-title'>Delete Offer</DialogTitle></Grid>
        <Grid item><IconButton color="primary" onClick={() => handleClose()} className={classes.close}><CloseIcon /></IconButton></Grid>
      </Grid>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Are you sure you want to <strong>delete</strong> this offer:</DialogContentText>
        <div className={classes.content}>
          <h6 className={classes.heading1}>{props.offer?.offerId}</h6>
          <h4 className={classes.heading2}>{description}</h4>
        </div>
        {message && message.description &&
          <Alert
            variant='outlined'
            severity={message.type as any}
            className={classes.alert as any}
            action={isLoading ? <div className={classes.progress}><CircularProgress color="secondary" size={20} /></div> : <></>}
          >
            <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">Cancel</Button>
        <Button onClick={() => handleSubmit()} color="primary" disabled={isLoading} variant="contained" disableElevation>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
