import { useDataStore } from "../../hooks/useDataStore";

import { OfferTable } from '../Trade/Offer/OfferTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const Offers = () => {
  const { dataOfferOpen, isLoadingOfferOpen, refreshOfferOpen  } = useDataStore();
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <h2 className={classes.heading}>Open Deals</h2>
        <OfferTable data={dataOfferOpen} isLoading={isLoadingOfferOpen}  mutate={refreshOfferOpen} />
      </Paper>
    </div>
  );
};