export const shrinkUUID = (uuid: string) => {
  if (uuid) {
    return `${uuid.substring(0, 6)}...${uuid.substring(uuid.length - 4)}`;
  }
  return '';
};

export const shrinkULID = (ulid: string) => {
  if (ulid) {
    return `${ulid.substring(0, 4)}...${ulid.substring(ulid.length - 4)}`;
  }
  return '';
};