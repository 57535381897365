import { useMemo } from "react";
import { useAuthStore } from "../../hooks/useAuthStore";
import { TradeState } from "../../services/trade";

import { makeStyles } from 'tss-react/mui';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const useStyles = makeStyles()((theme) => ({
  stepper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  label: {
    fontSize: '0.7em'
  }
}));

export const TradeStepper = (props: any) => {
  const { currentUser } = useAuthStore();
  const activeStep = useMemo(() => {
    if (props?.trade?.l1?.state && props?.trade?.l2?.state) {
      switch (props.trade.l1.state) {
        case TradeState.created: return 0; 
        case TradeState.confirmed: return 0; 
        case TradeState.waitL2Auth: return 1; 
        case TradeState.execL1Txn: return 2; 
        case TradeState.waitL1Txn: return 3; 
        case TradeState.failL1Txn: return 5; 
        case TradeState.waitL2Capt: return 3; 
        case TradeState.enqueueL1Balance: return 4; 
        case TradeState.waitL1Balance: return 4; 
        case TradeState.completed: return 6; 
        case TradeState.cancelled: return 6; 
        case TradeState.failed: return 6; 
      }
      switch (props.trade.l2.state) {
        case TradeState.created: return 0; 
        case TradeState.confirmed: return 0; 
        case TradeState.execL2Auth: return 1; 
        case TradeState.waitL1Txn: return 2; 
        case TradeState.failL2Auth: return 5; 
        case TradeState.execL2Capt: return 2; 
        case TradeState.enqueueL2Balance: return 4; 
        case TradeState.waitL2Balance: return 4; 
        case TradeState.completed: return 6; 
        case TradeState.cancelled: return 6; 
        case TradeState.failed: return 6; 
      }
    }
    return 0;
  }, [props.trade]);

  const errorStep = useMemo(() => {
    if (props?.trade?.l1?.state && props?.trade?.l2?.state) {
      switch (props.trade.l1.state) {
        case TradeState.cancelled: return true; 
        case TradeState.failed: return true; 
      }
      switch (props.trade.l2.state) {
        case TradeState.cancelled: return true; 
        case TradeState.failed: return true; 
      }
    }
    return false;
  }, [props.trade]);

  const { classes } = useStyles();
  return (
    <>
      {currentUser && props.trade && (
        <div className={classes.stepper}>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step><StepLabel classes={{ label: classes.label}}>Matched</StepLabel></Step>
            <Step><StepLabel classes={{ label: classes.label}}>Confirmed</StepLabel></Step>
            <Step><StepLabel classes={{ label: classes.label}}>Validated</StepLabel></Step>
            <Step><StepLabel classes={{ label: classes.label}}>Trading</StepLabel></Step>
            <Step><StepLabel classes={{ label: classes.label}}>Settled</StepLabel></Step>
            <Step><StepLabel classes={{ label: classes.label}} error={errorStep}>Completed</StepLabel></Step>
          </Stepper>
        </div>
      )}
    </>
  );
}
