import { useState, useEffect } from 'react';
import { format, formatDistance } from 'date-fns';

import { confirmTrade } from '../../../services/trade';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()(theme => ({
  grid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  confirm: {
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    marginTop: theme.spacing(0.5),
  },
  close: {
    margin: theme.spacing(1)
  }
}));

export const ConfirmModal = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const description = (props.offer ? `Buy ${Number(props.offer.amount).toFixed(2)} ${props.offer.symbolSend}` : '');

  const handleSubmit = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Initiating Trade: <strong>${description}</strong> ... ` });
    confirmTrade(props.offer?.offerId).then((data) => {
      console.log('UpdateOffer', data);
      setLoading(false);
      if (data) {
        setMessage({ type: 'success', description: `Offer: <strong>${description}</strong> updated <small>at ${new Date().toString()}</small>` });
        props.handleSubmit(props.offer?.offerId);
      }
    }).catch(e => {
      console.log(e);
      setLoading(false);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      }
    });
  };

  const handleClose = () => {
    props.closeModal();
  };

  useEffect(() => {
    setMessage({ type: 'info', description: '' });
  }, [props.open]);

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="space-between">
        <Grid item><DialogTitle id='form-dialog-title'>Select Deal</DialogTitle></Grid>
        <Grid item><IconButton color="primary" onClick={() => handleClose()} className={classes.close}><CloseIcon /></IconButton></Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.offer && (
          <>
            <DialogContentText>You are about to initiate the following trade:</DialogContentText>
            <Grid container spacing={1} className={classes.grid}>
              <Grid item xs={4} className={classes.itemTitle}>Id</Grid><Grid item xs={8} className={classes.itemValue}>{props.offer?.offerId}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>You Receive</Grid><Grid item xs={8} className={classes.itemValue}>{Number(props.offer?.amount).toFixed(2)} {props.offer?.symbolSend}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>Exchange Rate</Grid><Grid item xs={8} className={classes.itemValue}>{(Number(props.offer?.rate)).toFixed(2)} {props.offer?.symbolSend}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>You Send</Grid><Grid item xs={8} className={classes.itemValue}>{(Number(props.offer?.amount) * Number(props.offer?.rate)).toFixed(2)} {props.offer?.symbolRecv}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>Expires</Grid><Grid item xs={8} className={classes.itemValue}>{format(new Date(props.offer?.expireAt), 'dd-MMM-yyyy HH:mm')} ( {formatDistance(new Date(props.offer?.expireAt), new Date())} )</Grid>
            </Grid>
          </>
        )}
        {message && message.description &&
          <Alert
            variant='outlined'
            severity={message.type as any}
            className={classes.alert}
            action={isLoading ? <div className={classes.progress}><CircularProgress color="secondary" size={20} /></div> : <></>}
          >
            <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation disabled={isLoading}>Trade</Button>
      </DialogActions>
    </Dialog>
  );
};
