import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: "'Cera Pro', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },  
  palette: {
    primary: {
      light: "#f58e4e",
      main: "#F37222",
      dark: "#F37222",
      contrastText: "#fff",
    },
  },
  
});
