import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/useAuthStore";
import { useDataStore } from "../../hooks/useDataStore";

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { Wallet } from '../../shared/Card/Wallet';
import { Online } from '../../shared/Card/Online';

import { Listings } from './Listings';
import { TradeTable } from './Trade/TradeTable';
import { OfferTable } from './Offer/OfferTable';

import { makeStyles } from 'tss-react/mui';
import { Paper, Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cards: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  heading: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const Trade = () => {
  const navigate = useNavigate();
  const { isAuthorized } = useAuthStore();
  const { dataOfferOpen, isLoadingOfferOpen, refreshOfferOpen } = useDataStore();
  const { dataTradeRecent, isLoadingTradeRecent, refreshTradeRecent, mutateTradeRecent } = useDataStore();
  const [selectedTrade, setSelectedTrade] = useState<any>();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Trade Assets</h1>
        <h4 className={classes.subtitle}><span>Buy</span> Tokenised Assets</h4>
        <Grid container justifyContent="space-between" spacing={3} className={classes.cards}>
          <Grid item xl={4} md={12}>
            <Grid container spacing={3}>
              <Grid item xl={12} lg={6} md={12} sm={12} xs={12}><Wallet /></Grid>
              <Grid item xl={12} lg={6} md={12} sm={12} xs={12}><Online /></Grid>
            </Grid>
          </Grid>
          <Grid item xl={8} md={12}>
            <Paper className={classes.paper}>
              <h2 className={classes.heading}>Matched Deals</h2>
              <TradeTable data={dataTradeRecent} isLoading={isLoadingTradeRecent} mutate={mutateTradeRecent} refresh={refreshTradeRecent} selectedTrade={selectedTrade} selectTrade={setSelectedTrade} />
            </Paper>
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <h2 className={classes.heading}>Open Deals</h2>
          <OfferTable data={dataOfferOpen} isLoading={isLoadingOfferOpen} mutate={refreshOfferOpen} />
        </Paper>
        <Listings />
      </div>
    </AppLayout>
  );
};